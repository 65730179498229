<template>
  <div>
    <div class="reg-step-1-right-title">
      <span class="reg-step-number">{{ $lang.app.application_step.replace(':step', 3).replace(':total', 5) }}</span>
      <h3 class="reg-step-title">{{ $lang.app.child_info }}</h3>
    </div>
    <div class="reg-step-1-form-container">
      <h4 class="hint-title">{{ $lang.app.applicant_form_hint }}</h4>
      <div class="reg-step-form">
        <div v-if="childrenForms.length > 1" class="my-apps-detail-tabs-links children-buttons-wrapper">
          <a v-for="(childrenForm, index) in childrenForms" :key="index" href @click.prevent="selectChildren(index)"
            class="children-button" :class="{ 'my-apps-detail-tabs-links-active': index === activeForm }">
            {{ childrenForm.name || $lang.app.new_form }}
          </a>
        </div>
        <Form v-for="(childrenForm, index) in childrenForms" :key="index" :ref="`form-${index}`"
          :class="{ 'inactive-form': index !== activeForm }" @submit="submit" :validation-schema="schema">
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.child_citizenship }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <i class="bi-chevron-down"></i>
              <Field as="select" name="citizenship" v-model="childrenForm.citizenship">
                <option value="" disabled>{{ $lang.app.select_from }}</option>
                <option v-for="citizenship in citizenships" :key="citizenship.value" :value="citizenship.value">{{
                  citizenship.label}}</option>
              </Field>
            </div>
            <ErrorMessage name="citizenship" class="recovery-label-error" />
          </div>
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.pin }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="pin" type="text" v-maska="'##############'"
                @change="setGenderAndDateOfBirth($event.target.value, index)" v-model="childrenForm.pin" />
              <div class="tooltip-target">
                <i class="bi-question-circle"></i>
                <div class="tooltip-content tooltip-content-small">
                  <p>{{ $lang.app.birth_certificate_des }}</p>
                  <p>{{ $lang.app.birth_certificate_example }}</p>
                  <div class="passport-images">
                    <div class="passport-image">
                      <img src="../../assets/images/birth-cert.png" alt="">
                      <span>{{ $lang.app.birth_certificate }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ErrorMessage name="pin" class="recovery-label-error" />
          </div>
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.surname }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="surname" type="text"
                v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[а-яА-ЯЁё ҢңӨөҮү-]/ } } }"
                :title="$lang.app.only_cyrillic" v-model="childrenForm.surname" />
              <ExtraKyrgyzLetters @change="childrenForm.surname += $event" />
            </div>
            <ErrorMessage name="surname" class="recovery-label-error" />
          </div>
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.name }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="name" type="text" v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[а-яА-ЯЁё ҢңӨөҮү-]/ } } }"
                :title="$lang.app.only_cyrillic" v-model="childrenForm.name" />
              <ExtraKyrgyzLetters @change="childrenForm.name += $event" />
            </div>
            <ErrorMessage name="name" class="recovery-label-error" />
          </div>
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.middle_name }} </label>
            <div class="reg-step-1-select-container">
              <Field name="middleName" type="text"
                v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[а-яА-ЯЁё ҢңӨөҮү-]/ } } }"
                :title="$lang.app.only_cyrillic" v-model="childrenForm.middleName" />
              <ExtraKyrgyzLetters @change="childrenForm.middleName += $event" />
            </div>
            <ErrorMessage name="middleName" class="recovery-label-error" />
          </div>
          <div class="double-input">
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.birth_date }} <i>*</i></label>
              <div class="reg-step-1-select-container">
                <Field name="dateOfBirth" type="date" v-model="childrenForm.dateOfBirth" readonly="readonly" />
              </div>
              <ErrorMessage name="dateOfBirth" class="recovery-label-error" />
            </div>
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.child_gender }} <i>*</i></label>
              <div class="reg-step-1-select-container"><i class="bi-chevron-down"></i>
                <Field as="select" name="gender" v-model="childrenForm.gender">
                  <option value="" disabled>{{ $lang.app.select_from }}</option>
                  <option v-for="gender in genders " :key="gender.value" :value="gender.value" disabled>{{ gender.label
                    }}</option>
                </Field>
              </div>
              <ErrorMessage name="gender" class="recovery-label-error" />
            </div>
          </div>
          <div v-if="childrenForm.citizenship === 'KG'" key="KG" class="double-input">
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.birth_certificate_seria }} <i>*</i></label>
              <div class="reg-step-1-select-container"><i class="bi-chevron-down"></i>
                <Field as="select" name="birthCertificateRange" v-model="childrenForm.birthCertificateRange">
                  <option value="" disabled>{{ $lang.app.select_from }}</option>
                  <option v-for="birthCertificateRange in birthCertificateRanges" :key="birthCertificateRange"
                    :value="birthCertificateRange">{{ birthCertificateRange }}</option>
                </Field>
              </div>
              <ErrorMessage name="birthCertificateRange" class="recovery-label-error" />
            </div>
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.birth_certificate_number }} <i>*</i></label>
              <div class="reg-step-1-select-container">
                <Field name="birthCertificateId" type="text" v-maska="'#######'"
                  v-model="childrenForm.birthCertificateId" />
              </div>
              <ErrorMessage name="birthCertificateId" class="recovery-label-error" />
            </div>
          </div>
          <div v-if="childrenForm.citizenship === 'FOREIGN'" key="FOREIGN" class="double-input">
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.birth_certificate_seria }} <i>*</i></label>
              <div class="reg-step-1-select-container">
                <Field name="birthCertificateRange" type="text" v-model="childrenForm.birthCertificateRange" />
              </div>
              <ErrorMessage name="birthCertificateRange" class="recovery-label-error" />
            </div>
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.birth_certificate_number }} <i>*</i></label>
              <div class="reg-step-1-select-container">
                <Field name="birthCertificateId" type="text" v-model="childrenForm.birthCertificateId" />
              </div>
              <ErrorMessage name="birthCertificateId" class="recovery-label-error" />
            </div>
          </div>
          <div v-if="type === 'PRESCHOOL'" class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.privilege_type }} <i>*</i></label>
            <div class="reg-step-1-select-container select-control-wrapper">
              <i class="bi-chevron-down"></i>
              <Field name="privilegeTypeId" v-slot="{ field }">
                <Multiselect v-bind="field" v-model="childrenForm.privilegeTypeId" :options="privilegeTypes"
                  :placeholder="$lang.app.select_from" valueProp="id" label="title"
                  :noOptionsText="$lang.app.list_empty" :noResultsText="$lang.app.not_found">
                </Multiselect>
              </Field>
            </div>
            <ErrorMessage name="privilegeTypeId" class="recovery-label-error" />
          </div>
          <div v-if="childrenForm.privilegeTypeId && childrenForm.privilegeTypeId !== 1"
            class="file-input reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.file_message }}</label>
            <Field type="file" name="privilegeTypeFileDemo" accept="application/pdf"
              @change="event => childrenForm.privilegeTypeFileDemo = event.target.files[0]" />
            <ErrorMessage name="privilegeTypeFilename" class="recovery-label-error" />
            <ErrorMessage name="privilegeTypeFileDemo" class="recovery-label-error" />
          </div>
          <div class="reg-step-form-item">
            <div class="checkbox-container">
              <Field type="checkbox" name="personalDataAgreement" :id="`childrenPersonalDataAgreement${index}`"
                v-model="childrenForm.personalDataAgreement" :value="true" />
              <label :for="`childrenPersonalDataAgreement${index}`">
                <span>{{ $lang.app.personal_data_agreement }}</span>
              </label>
              <span class="modal-target" @click="openPersonalDataAgreementModal">({{ $lang.app.review }})</span>
            </div>
            <ErrorMessage name="personalDataAgreement" class="recovery-label-error" />
          </div>
          <div class="reg-step-btn">
            <button @click.prevent="$emit('go-back')">{{ $lang.app.back }}</button>
            <button v-if="childrenForms.length > 1" @click.prevent="removeChildren(index)" class="button-transparent">{{
              $lang.app.delete_child }}</button>
            <button @click.prevent="addChildren" class="button-transparent">{{ $lang.app.add_child }}</button>
            <button class="button-green" type="submit">{{ $lang.app.forward }}</button>
          </div>
        </Form>
      </div>
    </div>
    <div v-if="personalDataAgreementModal" class="modal">
      <div class="modal-background" @click="personalDataAgreementModal = false"></div>
      <div class="modal-content lg">
        <iframe src="/standard_form.pdf"></iframe>
        <div class="reg-step-form-item">
          <div class="checkbox-container">
            <Field type="checkbox" name="personalDataAgreement" id="childrenPersonalDataAgreementModal"
              v-model="personalDataAgreement" :value="true" />
            <label for="childrenPersonalDataAgreementModal">
              <span>{{ $lang.app.personal_data_agreement }}</span>
            </label>
          </div>
          <ErrorMessage name="personalDataAgreement" class="recovery-label-error" />
        </div>
      </div>
      <button class="modal-close" @click="personalDataAgreementModal = false"><i class="bi-x"></i></button>
    </div>
  </div>
</template>
<script>
import { maska } from 'maska'
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import ExtraKyrgyzLetters from '../ExtraKyrgyzLetters.vue'
import fileUploadMixin from "../../mixins/fileUploadMixin";

export default {
  name: 'ChildrenForm',
  components: {
    Field,
    Form,
    ErrorMessage,
    ExtraKyrgyzLetters,
  },
  directives: { maska },
  mixins: [fileUploadMixin],
  props: {
    applicantForm: Object,
    genders: Array,
    birthCertificateRanges: Array,
    privilegeTypes: Array,
    type: String,
    citizenships: Array,
  },
  data() {
    return {
      activeForm: null,
      childrenForms: [],
      schema: yup.object({
        pin: yup.string().min(14).
          required().matches(
            /[1,2][0-3][0-9][0-1][0-9][1,2][0,9][0-9]{7}/ 
          ),        surname: yup.string().required(),
        name: yup.string().required(),
        dateOfBirth: yup.mixed().required(),
        gender: yup.mixed().required(),
        citizenship: yup.mixed().required(),
        birthCertificateRange: yup.mixed().when('citizenship', {
          is: (val) => ['KG', 'FOREIGN'].includes(val),
          then: yup.mixed().required(),
          otherwise: yup.mixed().nullable(),
        }),
        birthCertificateId: yup.mixed().when('citizenship', {
          is: (val) => ['KG', 'FOREIGN'].includes(val),
          then: yup.mixed().required(),
          otherwise: yup.mixed().nullable(),
        }),
        privilegeTypeId: yup.mixed().when('type', {
          is: (val) => ['PRESCHOOL'].includes(val),
          then: yup.mixed().required(),
          otherwise: yup.mixed().nullable(),
        }),
        // privilegeTypeId: yup.mixed().required(),
        personalDataAgreement: yup.boolean().required(),
      }),
      personalDataAgreement: false,
      personalDataAgreementModal: false,
    }
  },
  mounted() {
    this.addChildrenForm()
    this.activeForm = 0
  },
  watch: {
    personalDataAgreement(value) {
      this.childrenForms[this.activeForm].personalDataAgreement = value
      if (value) {
        this.personalDataAgreementModal = false
      }
    }
  },
  methods: {
    setGenderAndDateOfBirth(value, index) {
      if (value.length > 1) {
        if (value[0] === '1') {
          this.childrenForms[index].gender = 'FEMALE'
        }
        if (value[0] === '2') {
          this.childrenForms[index].gender = 'MALE'
        }
      }
      if (value.length > 9) {
        this.childrenForms[index].dateOfBirth = value.substring(5, 9) + '-' + value.substring(3, 5) + '-' + value.substring(1, 3)
      }
    },
    openPersonalDataAgreementModal() {
      this.personalDataAgreement = this.childrenForms[this.activeForm].personalDataAgreement
      this.personalDataAgreementModal = true
    },
    removeChildren(index) {
      this.childrenForms.splice(index, 1);
      this.activeForm = 0
      this.$jump('#children-forms')
    },
    async checkChild() {
      this.$store.commit('startLoading')

      await this.$recaptchaLoaded()
      this.childrenForms[this.activeForm].recaptchaToken = await this.$recaptcha('login')

      if (!this.childrenForms[this.activeForm].privilegeTypeFilename && this.childrenForms[this.activeForm].privilegeTypeFileDemo) {
        try {
          this.childrenForms[this.activeForm].privilegeTypeFilename = await this.uploadFile(this.childrenForms[this.activeForm].privilegeTypeFileDemo)
        } catch (error) {
          this.$store.commit('stopLoading')
          if (error.response.status === 400 || error.response.status === 413) {
            this.$refs['form-' + this.activeForm].setErrors({
              privilegeTypeFileDemo: error.response.data.errors.file,
            });
          } else {
            this.$snotify.error(error)
          }
          return
        }
      }

      return new Promise((resolve) => {
        this.$axios.post('/children/check', {
          ...this.childrenForms[this.activeForm],
          applicantPin: this.applicantForm.pin,
          relationTypeId: this.applicantForm.relationTypeId,
          applicantCitizenship: this.applicantForm.citizenship,
        })
          .then(() => {
            resolve()
          }).catch(error => {
            if (error.response.status === 400) {
              this.$refs['form-' + this.activeForm].setErrors(error.response.data.errors);
              this.$jump('#children-forms')
            }
          }).finally(() => {
            this.$store.commit('stopLoading')
          })
      })
    },
    addChildren() {
      this.$refs['form-' + this.activeForm].validate().then(({ valid }) => {
        if (valid) {
          this.checkChild().then(() => {
            this.addChildrenForm()
            this.activeForm++
            this.$jump('#children-forms')
          })
        }
      })
    },
    selectChildren(index) {
      this.$refs['form-' + this.activeForm].validate().then(({ valid }) => {
        if (valid) {
          this.checkChild().then(() => {
            this.activeForm = index
          })
        }
      })
    },
    addChildrenForm() {
      if (this.type === 'SCHOOL') {
        this.childrenForms.push({
          pin: '',
          surname: '',
          name: '',
          middleName: '',
          dateOfBirth: null,
          gender: null,
          citizenship: null,
          birthCertificateRange: null,
          birthCertificateId: '',
          privilegeTypeId: 1,
          privilegeTypeFilename: null,
          personalDataAgreement: undefined,
          type: this.type,
          recaptchaToken: null,
        })
      }
      if (this.type === 'PRESCHOOL') {
        this.childrenForms.push({
          pin: '',
          surname: '',
          name: '',
          middleName: '',
          dateOfBirth: null,
          gender: null,
          citizenship: null,
          birthCertificateRange: null,
          birthCertificateId: '',
          privilegeTypeId: null,
          privilegeTypeFilename: null,
          privilegeTypeFileDemo: null,
          personalDataAgreement: undefined,
          type: this.type,
          recaptchaToken: null,
        })
      }
    },
    getCurrentDate(year = 0, month = 0, date = 0) {
      let today = new Date()
      return (today.getFullYear() + year) + '-' + (today.getMonth() + 1 + month) + '-' + (today.getDate() + date)
    },
    submit() {
      this.checkChild().then(() => {
        this.$emit('validated', this.childrenForms)
      })
    }
  }
}
</script>
<style>
.children-buttons-wrapper {
  flex-wrap: wrap;
}

.children-buttons-wrapper .children-button {
  white-space: nowrap;
  width: auto;
  padding: 20px;
}

.inactive-form {
  display: none;
}
</style>