<template>
  <div>
    <div class="reg-step-1-right-title">
      <span class="reg-step-number">{{ $lang.app.application_step.replace(':step', 2).replace(':total', 5) }}</span>
      <h3 class="reg-step-title">{{ $lang.app.parent_info }}</h3>
    </div>
    <div class="reg-step-1-form-container">
      <h4 class="hint-title">{{ $lang.app.applicant_form_hint }}</h4>
      <div class="reg-step-form">
        <Form @submit="submit" :validation-schema="schema">
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.applicant_type_label }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <i class="bi-chevron-down"></i>
              <Field as="select" name="relationTypeId"
                      v-model="applicantForm.relationTypeId">
                <option value="" disabled>{{ $lang.app.select_from }}</option>
                <option v-for="relationType in relationTypes" :key="relationType.id"
                        :value="relationType.id">{{ relationType.title }}</option>
              </Field>
            </div>
            <ErrorMessage name="relationTypeId" class="recovery-label-error" />
          </div>
          <div v-if="applicantForm.relationTypeId && applicantForm.relationTypeId === 3" class="file-input reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.file_message }}</label>
            <Field type="file" name="relationTypeFileDemo" accept="application/pdf"
                   @change="event => applicantForm.relationTypeFileDemo = event.target.files[0]"/>
            <ErrorMessage name="relationTypeFilename" class="recovery-label-error"/>
            <ErrorMessage name="relationTypeFileDemo" class="recovery-label-error"/>
          </div>
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.citizenship }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <i class="bi-chevron-down"></i>
              <Field as="select" name="citizenship"
                      v-model="applicantForm.citizenship">
                <option value="" disabled>{{ $lang.app.select_from }}</option>
                <option v-for="citizenship in citizenships" :key="citizenship.value"
                        :value="citizenship.value">{{ citizenship.label}}</option>
              </Field>
            </div>
            <ErrorMessage name="citizenship" class="recovery-label-error" />
          </div>
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.pin }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="pin" type="text"
                     v-maska="'##############'"
                     @change="setDateOfBirth($event.target.value)"
                     v-model="applicantForm.pin"/>
              <div class="tooltip-target">
                <i class="bi-question-circle"></i>
                <div class="tooltip-content">
                  <p>{{ $lang.app.id_card_des }}</p>
                  <p>{{ $lang.app.id_card_example }}</p>
                  <div class="passport-images">
                    <div class="passport-image">
                      <img src="../../assets/images/pin-2017.png" alt="">
                      <span>{{ $lang.app.id_card }}</span>
                    </div>
                    <div class="passport-image">
                      <img src="../../assets/images/pin-2004.png" alt="">
                      <span>{{ $lang.app.passport }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ErrorMessage name="pin" class="recovery-label-error" />
          </div>
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.surname }} <i>*</i></label>
            <div class="reg-step-1-select-container with-letter-buttons">
              <Field name="surname" type="text"
                     v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[а-яА-ЯЁё ҢңӨөҮү-]/ }}}"
                     :title="$lang.app.only_cyrillic"
                     v-model="applicantForm.surname"/>
              <ExtraKyrgyzLetters @change="applicantForm.surname += $event"/>
            </div>
            <ErrorMessage name="surname" class="recovery-label-error" />
          </div>
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.name }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="name" type="text"
                     v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[а-яА-ЯЁё ҢңӨөҮү-]/ }}}"
                     :title="$lang.app.only_cyrillic"
                     v-model="applicantForm.name"/>
              <ExtraKyrgyzLetters @change="applicantForm.name += $event"/>
            </div>
            <ErrorMessage name="name" class="recovery-label-error" />
          </div>
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.middle_name }} </label>
            <div class="reg-step-1-select-container">
              <Field name="middleName" type="text"
                     v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[а-яА-ЯЁё ҢңӨөҮү-]/ }}}"
                     :title="$lang.app.only_cyrillic"
                     v-model="applicantForm.middleName"/>
              <ExtraKyrgyzLetters @change="applicantForm.middleName += $event"/>
            </div>
            <ErrorMessage name="middleName" class="recovery-label-error" />
          </div>
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.birth_date }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="dateOfBirth" type="date"
                     v-model="applicantForm.dateOfBirth"  readonly="readonly"/>
            </div>
            <ErrorMessage name="dateOfBirth" class="recovery-label-error" />
          </div>
          <div v-if="applicantForm.citizenship === 'KG'" key="KG"
               class="double-input">
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.passport_seria }} <i>*</i></label>
              <div class="reg-step-1-select-container">
                <i class="bi-chevron-down"></i>
                <Field as="select" name="passportRange"
                       v-model="applicantForm.passportRange">
                  <option value="" disabled>{{ $lang.app.select_from }}</option>
                  <option v-for="passportRange in passportRanges" :key="passportRange"
                          :value="passportRange">{{ passportRange }}</option>
                </Field>
              </div>
              <ErrorMessage name="passportRange" class="recovery-label-error" />
            </div>
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.passport_number }} <i>*</i></label>
              <div class="reg-step-1-select-container">
                <Field name="passportId" type="text"
                       v-maska="'#######'"
                       v-model="applicantForm.passportId"/>
                <div class="tooltip-target">
                  <i class="bi-question-circle"></i>
                  <div class="tooltip-content tooltip-content-small">
                    <p>{{ $lang.app.passport_number_des }}</p>
                    <p>{{ $lang.app.passport_number_example }}</p>
                    <div class="passport-images">
                      <div class="passport-image">
                        <img src="../../assets/images/id-2017.png" alt="">
                        <span>{{ $lang.app.id_card }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ErrorMessage name="passportId" class="recovery-label-error" />
            </div>
          </div>
          <div v-if="applicantForm.citizenship === 'FOREIGN'" key="FOREIGN"
               class="double-input">
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.country_code }} <i>*</i></label>
              <div class="reg-step-1-select-container select-control-wrapper">
                <i class="bi-chevron-down"></i>
                <Field name="foreignCode" v-slot="{ field }">
                  <Multiselect
                      v-bind="field"
                      v-model="applicantForm.foreignCode"
                      :options="countryCodes"
                      :placeholder="$lang.app.select_from"
                      valueProp="code"
                      label="title"
                      :searchable="true"
                      :noOptionsText="$lang.app.list_empty"
                      :noResultsText="$lang.app.not_found">
                  </Multiselect>
                </Field>
              </div>
              <ErrorMessage name="foreignCode" class="recovery-label-error" />
            </div>
            <div class="reg-step-form-item">
              <label class="recovery-label">{{ $lang.app.document_number }} <i>*</i></label>
              <div class="reg-step-1-select-container">
                <Field name="foreignDoc" type="text"
                       v-model="applicantForm.foreignDoc"/>
              </div>
              <ErrorMessage name="foreignDoc" class="recovery-label-error" />
            </div>
          </div>
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.phone_number }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="phone" type="text" autocomplete="off"
                     v-maska="'+996(###)-##-##-##'"
                     placeholder="+996(XXX)-XX-XX-XX"
                     v-model="applicantForm.phone"/>
            </div>
            <ErrorMessage name="phone" class="recovery-label-error" />
          </div>
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.applicant_email }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="email" type="email"
                     v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[^а-яА-Я]/ }}}"
                     v-model="applicantForm.email"/>
            </div>
            <ErrorMessage name="email" class="recovery-label-error" />
          </div>
<!--          <div class="reg-step-form-item">-->
<!--            <label class="recovery-label">{{ $lang.app.second_applicant_email }}</label>-->
<!--            <div class="reg-step-1-select-container">-->
<!--              <Field name="extraEmail" type="email"-->
<!--                     v-maska="{ mask: 'Z*', tokens: { 'Z': { pattern: /[^а-яА-Я]/ }}}"-->
<!--                     v-model="applicantForm.extraEmail"/>-->
<!--            </div>-->
<!--            <ErrorMessage name="extraEmail" class="recovery-label-error" />-->
<!--          </div>-->
          <div v-if="type === 'SCHOOL'" class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.privilege_type }}</label>
            <div class="reg-step-1-select-container select-control-wrapper">
              <i class="bi-chevron-down"></i>
              <Field name="privilegeTypeId" v-slot="{ field }">
                <Multiselect
                    v-bind="field"
                    v-model="applicantForm.privilegeTypeId"
                    :options="privilegeTypes"
                    :placeholder="$lang.app.select_from"
                    valueProp="id"
                    label="title"
                    :noOptionsText="$lang.app.list_empty"
                    :noResultsText="$lang.app.not_found">
                </Multiselect>
              </Field>
            </div>
            <ErrorMessage name="privilegeTypeId" class="recovery-label-error" />
          </div>

          <div v-if="applicantForm.privilegeTypeId && applicantForm.privilegeTypeId !== 200" class="file-input reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.file_message }}</label>
            <Field type="file" name="privilegeTypeFileDemo" accept="application/pdf"
                   @change="event => applicantForm.privilegeTypeFileDemo = event.target.files[0]"/>
            <ErrorMessage name="privilegeTypeFilename" class="recovery-label-error"/>
            <ErrorMessage name="privilegeTypeFileDemo" class="recovery-label-error"/>
          </div>
          <div class="reg-step-form-item">
            <div class="checkbox-container">
              <Field type="checkbox" name="personalDataAgreement"
                     id="applicantPersonalDataAgreement"
                     v-model="applicantForm.personalDataAgreement"
                     :value="true"/>
              <label for="applicantPersonalDataAgreement">
                <span>{{ $lang.app.personal_data_agreement }}</span>
              </label>
              <span class="modal-target" @click="personalDataAgreementModal = true">({{ $lang.app.review }})</span>
            </div>
            <ErrorMessage name="personalDataAgreement" class="recovery-label-error"/>
          </div>
          <div class="reg-step-btn">
            <button @click.prevent="$emit('go-back')">{{ $lang.app.back }}</button>
            <button class="button-green" type="submit">{{ $lang.app.forward }}</button>
          </div>
        </form>
      </div>
    </div>
    <div v-if="personalDataAgreementModal" class="modal">
      <div class="modal-background" @click="personalDataAgreementModal = false"></div>
      <div class="modal-content lg">
        <iframe src="/standard_form.pdf"></iframe>
        <div class="reg-step-form-item">
          <div class="checkbox-container">
            <Field type="checkbox" name="personalDataAgreement"
                   id="applicantPersonalDataAgreementModal"
                   v-model="applicantForm.personalDataAgreement"
                   :value="true"/>
            <label for="applicantPersonalDataAgreementModal">
              <span>{{ $lang.app.personal_data_agreement }}</span>
            </label>
          </div>
          <ErrorMessage name="personalDataAgreement" class="recovery-label-error" />
        </div>
      </div>
      <button class="modal-close" @click="personalDataAgreementModal = false"><i class="bi-x"></i></button>
    </div>
  </div>
</template>
<script>
import { maska } from 'maska'
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import ExtraKyrgyzLetters from '../ExtraKyrgyzLetters.vue'
import fileUploadMixin from "../../mixins/fileUploadMixin";

const forbiddenEmailDomains = ['gmail.ru', 'mail.com'];

export default {
  name: 'ApplicantForm',
  components: {
    Field,
    Form,
    ErrorMessage,
    ExtraKyrgyzLetters,
  },
  directives: { maska },
  mixins: [fileUploadMixin],
  props: {
    type: String,
    relationTypes: Array,
    countryCodes: Array,
    citizenships: Array,
    passportRanges: Array,
    privilegeTypes: Array
  },
  data() {
    return {
      applicantForm: {
        type: this.type,
        relationTypeId: null,
        relationTypeFilename: '',
        relationTypeFileDemo: null,
        citizenship: null,
        pin: '',
        surname: '',
        name: '',
        middleName: '',
        dateOfBirth: null,
        passportRange: null,
        passportId: '',
        foreignCode: null,
        foreignDoc: '',
        phone: '',
        email: '',
        extraEmail: '',
        privilegeTypeFilename: null,
        privilegeTypeFileDemo: null,
        privilegeTypeId: this.type === 'PRESCHOOL' ? 200 : null,
        personalDataAgreement: undefined,
        recaptchaToken: null,
      },
      schema: yup.object({
        relationTypeId: yup.mixed().required(),
        relationTypeFileDemo: yup.mixed().when('relationTypeId', {
          is: 3,
          then: yup.mixed().required(),
          otherwise: yup.mixed().nullable(),
        }),
        citizenship: yup.mixed().required(),
        pin: yup.string().min(14).
          required().matches(
            /[1,2][0-3][0-9][0-1][0-9][1,2][0,9][0-9]{7}/ 
          ),        surname: yup.string().required(),
        name: yup.string().required(),
        dateOfBirth: yup.mixed().required(),
        passportRange: yup.mixed().when('citizenship', {
          is: 'KG',
          then: yup.mixed().required(),
          otherwise: yup.mixed().nullable(),
        }),
        passportId: yup.mixed().when('citizenship', {
          is: 'KG',
          then: yup.string().required().min(7),
          otherwise: yup.string().nullable(),
        }),
        foreignCode: yup.mixed().when('citizenship', {
          is: 'FOREIGN',
          then: yup.mixed().required(),
          otherwise: yup.string().nullable(),
        }),
        foreignDoc: yup.mixed().when('citizenship', {
          is: 'FOREIGN',
          then: yup.string().required(),
          otherwise: yup.string().nullable(),
        }),
        phone: yup.string().required()
            .matches(/\+996\([0-9]{3}\)-[0-9]{2}-[0-9]{2}-[0-9]{2}/, { excludeEmptyString: true }),
        email: yup.string().required().email().test('not-allowed-domains', 'Недопустимый домен электронной почты', function(value) {
          if (value) {
            const domain = value.split('@')[1];
            return !forbiddenEmailDomains.includes(domain);
          }
          return true;
          }),
        extraEmail: yup.string().nullable().email(),
        privilegeTypeId: this.type === 'PRESCHOOL' ? yup.mixed().nullable() : yup.mixed().required(),
        privilegeTypeFileDemo: this.type === 'PRESCHOOL' ?
            yup.mixed().nullable() :
            yup.mixed().when('privilegeTypeId', {
              is: 200,
              then: yup.mixed().nullable(),
              otherwise: yup.mixed().required(),
            }),
        personalDataAgreement: yup.boolean().required(),
      }),
      personalDataAgreementModal: false,
    }
  },
  watch: {
    'applicantForm.personalDataAgreement'(value) {
      if(value) {
        this.personalDataAgreementModal = false
      }
    },
  },
  methods: {
    setDateOfBirth(value) {
      if(value.length > 9) {
        this.applicantForm.dateOfBirth = value.substring(5, 9) + '-' + value.substring(3, 5) + '-' + value.substring(1, 3)
      }
    },
    submit(values, actions) {
      this.checkApplicant(actions)
    },
    async checkApplicant(actions) {
      this.$store.commit('startLoading')

      await this.$recaptchaLoaded()
      this.applicantForm.recaptchaToken = await this.$recaptcha('login')

      if(!this.applicantForm.relationTypeFilename && this.applicantForm.relationTypeFileDemo) {
        try {
          this.applicantForm.relationTypeFilename = await this.uploadFile(this.applicantForm.relationTypeFileDemo)
        } catch (error) {
          this.$store.commit('stopLoading');
          if(error.response.status === 400 || error.response.status === 413) {
            actions.setErrors({
              relationTypeFileDemo: error.response.data.errors.file,
            });
          } else {
            this.$snotify.error(error)
          }
          return
        }
      }

      if(!this.applicantForm.privilegeTypeFilename && this.applicantForm.privilegeTypeFileDemo) {
        try {
          this.applicantForm.privilegeTypeFilename = await this.uploadFile(this.applicantForm.privilegeTypeFileDemo)
        } catch (error) {
          this.$store.commit('stopLoading')
          if(error.response.status === 400 || error.response.status === 413) {
            actions.setErrors({
              privilegeTypeFileDemo: error.response.data.errors.file,
            });
          } else {
            this.$snotify.error(error)
          }
          return
        }
      }

      if(this.applicantForm.citizenship === 'FOREIGN') {
        this.applicantForm.passportId = this.applicantForm.foreignDoc
      }

      this.$axios.post('/applicant/check', {
        ...this.applicantForm,
        phone: this.applicantForm.phone.replace(/\D/g, "")
      })
        .then(() => {
          this.$emit('validated', this.applicantForm)
        }).catch(error => {
          if(error.response.status === 400) {
            actions.setErrors(error.response.data.errors);
            this.$jump('#applicant-form')
          }
        }).finally(() => {
          this.$store.commit('stopLoading');
        })
    }
  }
}
</script>