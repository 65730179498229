<template>
  <div>
    <div class="reg-step-1-right-title">
      <span class="reg-step-number">{{ $lang.app.application_step.replace(':step', 5).replace(':total', 5) }}</span>
      <h3 class="reg-step-title">{{ $lang.app.review_title }}</h3>
    </div>
    <form @submit.prevent="submit">
      <div class="reg-step-5-items">
        <h4 class="hint-title">{{ $lang.app.review_hint }}</h4>
        <div v-if="Object.keys(applicantForm).length" class="reg-step-5-item">
          <div class="reg-step-5-item-title"><h4>{{ $lang.app.parent_info }}</h4></div>
          <div class="reg-step-5-item-line" v-if="relationTypes.length"><p>{{ $lang.app.applicant_type_label }}:</p>
            <span>{{ relationTypes.find(relationType => relationType.id === applicantForm.relationTypeId).title }}</span>
          </div>
          <div v-if="applicantForm.relationTypeId === 3" class="reg-step-5-item-line">
            <p>{{ $lang.app.legal_representative_file }}:</p>
            <span>{{ applicantForm.relationTypeFilename }}</span>
          </div>
          <div class="reg-step-5-item-line"><p>{{ $lang.app.citizenship }}:</p>
            <span>{{ citizenships.find(citizenship => citizenship.value === applicantForm.citizenship).label }}</span>
          </div>
          <div class="reg-step-5-item-line"><p>{{ $lang.app.pin }}:</p>
            <span>{{ applicantForm.pin }}</span>
          </div>
          <div class="reg-step-5-item-line"><p>{{ $lang.app.applicant_full_name }}:</p>
            <span>{{ applicantForm.surname }} {{ applicantForm.name }} {{ applicantForm.middleName }}</span>
          </div>
          <div class="reg-step-5-item-line"><p>{{ $lang.app.birth_date }}:</p>
            <span>{{ applicantForm.dateOfBirth }}</span>
          </div>
          <div v-if="applicantForm.citizenship === 'KG'" class="reg-step-5-item-line"><p>{{ $lang.app.passport_seria_and_number }}:</p>
            <span>{{ applicantForm.passportRange }} {{ applicantForm.passportId }}</span>
          </div>
          <div v-if="applicantForm.citizenship === 'FOREIGN'" class="reg-step-5-item-line"><p>{{ $lang.app.country_code_and_document }}:</p>
            <span>{{ applicantForm.foreignCode }} {{ applicantForm.foreignDoc }}</span>
          </div>
          <div class="reg-step-5-item-line"><p>{{ $lang.app.phone_number }}:</p>
            <span>{{ applicantForm.phone }}</span>
          </div>
          <div class="reg-step-5-item-line"><p>{{ $lang.app.applicant_email }}:</p>
            <span>{{ applicantForm.email }}</span>
          </div>
<!--          <div class="reg-step-5-item-line"><p>{{ $lang.app.second_applicant_email }}:</p>-->
<!--            <span>{{ applicantForm.extraEmail }}</span>-->
<!--          </div>-->
          <div v-if="type === 'SCHOOL' && applicantPrivilegeTypes.length"
               class="reg-step-5-item-line">
            <p>{{ $lang.app.privilege_type }}:</p>
            <span v-if="applicantForm.privilegeTypeId">
              {{ applicantPrivilegeTypes.find(privilegeType => privilegeType.id === applicantForm.privilegeTypeId).title }}
            </span>
          </div>
          <div v-if="applicantForm.privilegeTypeId !== 200" class="reg-step-5-item-line">
            <p>{{ $lang.app.privilege_file }}:</p>
            <span>{{ applicantForm.privilegeTypeFilename }}</span>
          </div>
          <div class="reg-step-5-item-btn"><a href="" @click.prevent="$emit('go', 1)">{{ $lang.app.update_data }}</a></div>
        </div>
        <div class="reg-step-5-item">
          <div v-for="(childrenForm, index) in childrenForms" :key="index">
            <div class="reg-step-5-item-title"><h4>{{ $lang.app.child_info }}</h4></div>
            <div class="reg-step-5-item-line"><p>{{ $lang.app.child_citizenship }}:</p>
              <span>{{ childCitizenships.find(citizenship => citizenship.value === childrenForm.citizenship).label }}</span>
            </div>
            <div class="reg-step-5-item-line"><p>{{ $lang.app.pin }}:</p>
              <span>{{ childrenForm.pin }}</span>
            </div>
            <div class="reg-step-5-item-line"><p>{{ $lang.app.child_full_name }}:</p>
              <span>{{ childrenForm.surname }} {{ childrenForm.name }} {{ childrenForm.middleName }}</span>
            </div>
            <div class="reg-step-5-item-line"><p>{{ $lang.app.child_birth_date }}:</p><span>{{ childrenForm.dateOfBirth }}</span></div>
            <div class="reg-step-5-item-line"><p>{{ $lang.app.child_gender }}:</p>
              <span v-if="childrenForm.gender">{{ genders.find(gender => gender.value === childrenForm.gender).label }}</span>
            </div>
            <div class="reg-step-5-item-line">
              <p>{{ $lang.app.birth_certificate_seria_and_number }}:</p>
              <span>{{ childrenForm.birthCertificateRange }} {{ childrenForm.birthCertificateId }}</span>
            </div>
            <div v-if="type === 'PRESCHOOL' && childPrivilegeTypes.length"
                 class="reg-step-5-item-line"><p>{{ $lang.app.privilege_type }}:</p>
              <span v-if="childrenForm.privilegeTypeId">
                {{ childPrivilegeTypes.find(privilegeType => privilegeType.id === childrenForm.privilegeTypeId).title }}
              </span>
            </div>
            <div v-if="childrenForm.privilegeTypeId !== 1" class="reg-step-5-item-line">
              <p>{{ $lang.app.privilege_file }}:</p>
              <span>{{ childrenForm.privilegeTypeFilename }}</span>
            </div>
          </div>
          <div class="reg-step-5-item-btn"><a href="" @click.prevent="$emit('go', 2)">{{ $lang.app.update_data }}</a></div>
        </div>
        <div v-if="organizationForm" class="reg-step-5-item">
          <div class="reg-step-5-item-title"><h4>{{ $lang.app.organization }}:</h4></div>
          <div class="reg-step-5-item-line" v-if="languages.length"><p>{{ $lang.app.language_label }}:</p>
            <span v-if="organizationForm.languageId">
              {{ languages.find(language => language.id === organizationForm.languageId).title }}
            </span>
          </div>
          <div class="reg-step-5-item-line"><p>{{ $lang.app.organization }}:</p>
            <span v-if="organizationForm.organizationId">
              {{ getOrganization }}
            </span>
          </div>
          <div class="reg-step-5-item-line">
            <span v-if="organizationForm.shortTermGroup">
              {{ $lang.app.short_term_group }}
            </span>
          </div>
          <div class="reg-step-5-item-btn"><a href="" @click.prevent="$emit('go', 3)">{{ $lang.app.update_data }}</a></div>
        </div>
        <div class="reg-step-btn">
          <button type="submit">{{ $lang.app.verify_submit }}</button>
        </div>
      </div>
    </form>
    <div v-if="successModal" class="modal">
      <div class="modal-background"></div>
      <div class="modal-content">
        <div class="application-success">
          <div class="success-title">
            <i class="bi-check-all"></i>
            <h4>{{ $lang.app.application_success_title }}</h4>
          </div>
          <div class="success-content">
            <p>{{ $lang.app.application_success_content }}</p>
          </div>
          <div class="controls">
            <button @click="$router.push('/')">{{ $lang.app.to_main }}</button>
            <button class="button-green" @click="toApplicationStatus">{{ $lang.app.application_check }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Completion',
  data() {
    return {
      successModal: false,
    }
  },
  props: [
    'type',
    'applicantForm',
    'childrenForms',
    'organizationForm',
    'relationTypes',
    'citizenships',
    'childCitizenships',
    'applicantPrivilegeTypes',
    'genders',
    'childPrivilegeTypes',
    'languages',
    'organizationTypes',
    'organizations',
  ],
  computed: {
    getOrganization(){
      let organization = this.organizations.find(organization => organization.id === this.organizationForm.organizationId)
      return organization ? organization.label : ''
    }
  },
  methods: {
    toApplicationStatus() {
      if(this.type === 'SCHOOL') {
        this.$router.push('/application-status/school')
      }
      else if(this.type === 'PRESCHOOL') {
        this.$router.push('/application-status/preschool')
      }
    },
    async storeApplication(childrenForm, applicantId, childId) {
      await this.$recaptchaLoaded()
      let recaptchaToken = await this.$recaptcha('login')
      this.$axios.post('/application/create', {
        ...this.organizationForm,
        recaptchaToken: recaptchaToken,
        organizationGroupId: childrenForm.organizationGroupId,
        shortTermGroup: this.organizationForm.shortTermGroup,
        type: this.type,
        applicantId: applicantId,
        childrenId: childId,
        applicantPrivilegeId: this.applicantForm.privilegeTypeId,
        childPrivilegeId: childrenForm.privilegeTypeId,
        applicantPrivilegeFilename: this.applicantForm.privilegeTypeFilename,
        childPrivilegeFilename: childrenForm.privilegeTypeFilename
      }).then(() => {
        this.successModal = true
      }).finally(() => {
        this.$store.commit('stopLoading')
      })
    },
    storeRelation(applicantId, childId) {
      this.$axios.post('/applicant-children/create', {
        applicantId: applicantId,
        childId: childId,
        relationTypeId: this.applicantForm.relationTypeId,
      })
    },
    async storeChild(childrenForm, applicantId) {
      await this.$recaptchaLoaded()
      let recaptchaToken = await this.$recaptcha('login')
      this.$axios.post('/children/create', {
        ...childrenForm,
        recaptchaToken: recaptchaToken,
        applicantPin: this.applicantForm.pin,
        relationTypeId: this.applicantForm.relationTypeId,
        applicantCitizenship: this.applicantForm.citizenship,
      })
          .then(({data}) => {
            let childId = data.message
            this.storeRelation(applicantId, childId)
            this.storeApplication(childrenForm, applicantId, childId)
          })
    },
    async storeApplicant() {
      this.$store.commit('startLoading')

      await this.$recaptchaLoaded()
      let recaptchaToken = await this.$recaptcha('login')

      this.$axios.post('/applicant/create', {
        ...this.applicantForm,
        phone: this.applicantForm.phone.replace(/\D/g, ""),
        recaptchaToken: recaptchaToken
      })
      .then(({data}) => {
        let applicantId = data.message
        this.childrenForms.forEach(childrenForm => {
          this.storeChild(childrenForm, applicantId)
        })
      }).catch(error => {
        if(error.response.status === 406) {
          if(error.response.data.message === 'organization_border_exist') {
            this.$store.commit('showOrganizationNotFoundErrorModal')
          }
          if(error.response.data.message === 'applicant_address_not_found_in_darek_register') {
            this.$store.commit('showAddressNotFoundErrorModal')
          }
        }
        this.$store.commit('stopLoading');
      }).finally(() => {
        this.$store.commit('stopLoading')
      })
    },
    submit() {
      this.storeApplicant()
    }
  }
}
</script>
<style>
.application-success .success-title {
  display: flex;
  align-items: center;
  justify-content: center;
}
.application-success .success-title i {
  margin-bottom: 1rem;
  flex-shrink: 0;
  color: mediumseagreen;
  font-size: 48px;
  display: inline-block;
  margin-right: 20px;
}
.application-success .success-content {
  text-align: center;
  margin-bottom: 40px;
}
.application-success .success-content .success-documents {
  text-align: left;
}
.application-success .controls {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.application-success .controls button {
  height: 46px;
  line-height: 46px;
  text-align: center;
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  border-radius: 5px;
  display: inline-block;
  vertical-align: middle;
  background: linear-gradient(to bottom, #63b8d8 0%, #367db7 100%);
  border: none;
  padding: 0 24px;
  flex-shrink: 0;
  margin-bottom: 8px;
}
.application-success .controls button:hover {
  background: linear-gradient(to bottom, #74e8fc 0%, #375fb7 100%);
}
.application-success .controls button.button-green {
  background: linear-gradient(to bottom, limegreen 0%, forestgreen 100%);
}
.application-success .controls button.button-green:hover {
  background: linear-gradient(to bottom, springgreen 0%, green 100%);
}

.reg-step-5-items .hint-title {
  margin: 0 11.1111% 30px;
}
.hint-title {
  padding: 20px;
  margin: 0 0 30px;
  background: rgba(76,154,199,0.15);
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
}
.reg-step-5-item {
  border-top: 1px solid #d0d0d0;
  padding: 0 11.1111% 35px;
}
.reg-step-5-item-title {
  padding: 30px 0;
}
.reg-step-5-item-title h4 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
  color: #4c9ac7;
  text-align: center;
}
.reg-step-5-item-line {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 10px;
}
.reg-step-5-item-line p {
  margin: 0;
  color: #888;
  font-size: 18px;
  width: 48%;
  text-align: end;
}
.reg-step-5-item-line span {
  color: #1f1f1f;
  font-size: 18px;
  display: inline-block;
  width: 48%;
}
.reg-step-5-item-line-img p {
  text-align: center;
  margin-bottom: 5px;
  width: 100%;
}
.reg-step-5-item-img img {
  width: 100%;
  border: 3px solid lightblue;
  margin-bottom: 15px;
}
.reg-step-5-item-phones span {
  width: 100%;
}
.reg-step-5-item-btn {
  display: flex;
  justify-content: center;
}
.reg-step-5-item-btn a {
  display: inline-block;
  width: 48%;
  height: 46px;
  line-height: 46px;
  border-radius: 4px;
  overflow: hidden;
  text-align: center;
  font-weight: bold;
  padding: 0;
  transition: 0.5s;
  text-decoration: none;
  font-size: 18px;
  background: linear-gradient(to bottom, #efefef 0%, #e2e2e2 100%);
  border: 1px solid #d0d0d0;
  color: #4c9ac7;
}
@media screen and (max-width: 1200px) {
  .reg-step-5-items .hint-title {
    margin: 0 16.6666% 30px;
  }
  .reg-step-5-items .hint-title {
    margin-bottom: 30px;
  }
  .reg-step-1-right .hint-title {
    margin-bottom: 15px;
  }
  .reg-step-5-item {
    padding: 0 16.6666% 35px;
  }
  .reg-step-5-item-line {
    flex-wrap: wrap;
    margin-bottom: 15px;
  }
  .reg-step-5-item-line p, .reg-step-5-item-line span, .reg-step-5-item-btn a {
    width: 100%;
  }
  .reg-step-5-item-line p, .reg-step-5-item-line span, .reg-step-5-item-btn a {
    width: 100%;
  }
  .reg-step-5-item-line-pass .reg-step-5-item-img img {
    width: 48%;
    height: 100%;
  }
  .reg-step-5-item-line p, .reg-step-5-item-line span, .reg-step-5-item-btn a {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .reg-step-5-items .hint-title {
    margin: 0 15px 30px;
  }
  .reg-step-5-item {
    padding: 0 15px 35px;
  }
  .hint-title {
    margin: 0 0 30px;
    padding: 10px;
  }
  .reg-step-5-item-line {
    padding: 0 30px;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 479px) {
  .reg-step-5-item-line {
    padding: 0;
  }
  .reg-step-5-item-img img {
    margin-bottom: 5px;
  }
}
</style>