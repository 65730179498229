<template>
  <div>
    <div v-if="type === 'PRESCHOOL'">
      <div class="reg-step-1-right-title content-container">
        <h2 class="instruction-title">
          {{ $lang.app.instruction.preschool.title }}
        </h2>
      </div>
      <div class="content content-container">
        <h3>{{ $lang.app.instruction.preschool.how_to_apply }}</h3>
        <h4>{{ $lang.app.instruction.preschool.fill_in }}</h4>
        <div class="content-step">
          <h4>1. {{ $lang.app.instruction.preschool.relation_type }}</h4>
          <ul>
            <li>{{ $lang.app.instruction.preschool.father }}</li>
            <li>{{ $lang.app.instruction.preschool.mother }}</li>
            <li>{{ $lang.app.instruction.preschool.legal_representative }}</li>
          </ul>
        </div>
        <div class="content-step">
          <h4>2. {{ $lang.app.instruction.preschool.applicant_info }}</h4>
          <ul>
            <li>{{ $lang.app.instruction.preschool.nationality }}</li>
            <li>
              <div>{{ $lang.app.instruction.preschool.passport_info }}</div>
              <div class="image">
                <div class="passport-image">
                  <div class="passport-face">
                    <div class="image-tab-title">{{ $lang.app.instruction.preschool.passport_front }}</div>
                    <img src="../../assets/images/passport-front.jpg" alt="">
                  </div>
                  <div class="passport-face">
                    <div class="image-tab-title">{{ $lang.app.instruction.preschool.passport_back }}</div>
                    <img src="../../assets/images/passport-back.jpg" alt="">
                  </div>
                </div>
              </div>
            </li>
            <li>{{ $lang.app.instruction.preschool.phone }}</li>
            <li>{{ $lang.app.instruction.preschool.email }}</li>
<!--            <li>{{ $lang.app.instruction.preschool.extra_email }}</li>-->
            <li>{{ $lang.app.instruction.preschool.privilege }}</li>
          </ul>
        </div>
        <div class="content-step">
          <h4>3. {{ $lang.app.instruction.preschool.children_info }}</h4>
          <ul>
            <li>{{ $lang.app.instruction.preschool.child_info }}</li>
            <li>
              <div>{{ $lang.app.instruction.preschool.birth_certificate_info }}</div>
              <div class="image">
                <div class="birth-certificate-face">
                  <div class="image-tab-title">{{ $lang.app.instruction.preschool.birth_certificate_back }}</div>
                  <img src="../../assets/images/birth-cert-back.png" alt="">
                </div>
              </div>
            </li>
            <li>{{ $lang.app.instruction.preschool.child_privilege }}</li>
          </ul>
        </div>
        <div class="content-step">
          <h4>4. {{ $lang.app.instruction.preschool.school_selection }}</h4>
          <ul>
            <li>{{ $lang.app.instruction.preschool.language }}</li>
            <li>{{ $lang.app.instruction.preschool.by_address }}</li>
          </ul>
        </div>
        <div class="content-step">
          <h4>5. {{ $lang.app.instruction.preschool.preview }}</h4>
          <ul>
            <li>{{ $lang.app.instruction.preschool.fill_in_check }}</li>
            <li>{{ $lang.app.instruction.preschool.completed_description }}</li>
          </ul>
        </div>
        <div class="continue-button">
          <button @click="$emit('validated')">{{ $lang.app.instruction.preschool.start_apply }}</button>
        </div>
      </div>
    </div>
    <div v-if="type === 'SCHOOL'">
      <div class="reg-step-1-right-title content-container">
        <h2 class="instruction-title">
          {{ $lang.app.instruction.school.title }}
        </h2>
      </div>
      <div class="content content-container">
        <h3>{{ $lang.app.instruction.school.how_to_apply }}</h3>
        <h4>{{ $lang.app.instruction.school.fill_in }}</h4>
        <div class="content-step">
          <h4>1. {{ $lang.app.instruction.school.relation_type }}</h4>
          <ul>
            <li>{{ $lang.app.instruction.school.father }}</li>
            <li>{{ $lang.app.instruction.school.mother }}</li>
            <li>{{ $lang.app.instruction.school.legal_representative }}</li>
          </ul>
        </div>
        <div class="content-step">
          <h4>2. {{ $lang.app.instruction.school.applicant_info }}</h4>
          <ul>
            <li>{{ $lang.app.instruction.school.nationality }}</li>
            <li>
              <div>{{ $lang.app.instruction.school.passport_info }}</div>
              <div class="image">
                <div class="passport-image">
                  <div class="passport-face">
                    <div class="image-tab-title">{{ $lang.app.instruction.school.passport_front }}</div>
                    <img src="../../assets/images/passport-front.jpg" alt="">
                  </div>
                  <div class="passport-face">
                    <div class="image-tab-title">{{ $lang.app.instruction.school.passport_back }}</div>
                    <img src="../../assets/images/passport-back.jpg" alt="">
                  </div>
                </div>
              </div>
            </li>
            <li>{{ $lang.app.instruction.school.phone }}</li>
            <li>{{ $lang.app.instruction.school.email }}</li>
<!--            <li>{{ $lang.app.instruction.school.extra_email }}</li>-->
            <li>{{ $lang.app.instruction.school.privilege }}</li>
          </ul>
        </div>
        <div class="content-step">
          <h4>3. {{ $lang.app.instruction.school.children_info }}</h4>
          <ul>
            <li>{{ $lang.app.instruction.school.child_info }}</li>
            <li>
              <div>{{ $lang.app.instruction.school.birth_certificate_info }}</div>
              <div class="image">
                <div class="birth-certificate-face">
                  <div class="image-tab-title">{{ $lang.app.instruction.school.birth_certificate_back }}</div>
                  <img src="../../assets/images/birth-cert-back.png" alt="">
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="content-step">
          <h4>4. {{ $lang.app.instruction.school.school_selection }}</h4>
          <h4>{{ $lang.app.instruction.school.by_first_lap }}</h4>
          <ul>
            <li>{{ $lang.app.instruction.school.language }}</li>
            <li>{{ $lang.app.instruction.school.by_address }}</li>
          </ul>
          <h4>{{ $lang.app.instruction.school.by_second_lap }}</h4>
          <ul>
            <li>{{ $lang.app.instruction.school.language }}</li>
            <li>{{ $lang.app.instruction.school.region_city }}</li>
            <li>{{ $lang.app.instruction.school.applicant_can_select }}</li>
          </ul>
        </div>
        <div class="content-step">
          <h4>5. {{ $lang.app.instruction.school.preview }}</h4>
          <ul>
            <li>{{ $lang.app.instruction.school.fill_in_check }}</li>
            <li>{{ $lang.app.instruction.school.completed_description }}</li>
          </ul>
        </div>
        <div class="continue-button">
          <button @click="$emit('validated')">{{ $lang.app.instruction.school.start_apply }}</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['type']
}
</script>
<style scoped>
.content-step .image {
  margin: 8px 0;
}
.content-step .image img {
  max-width: 100%;
}
.content-step .passport-image {
  display: flex;
}
.content-step .passport-image .passport-face {
  border: 1px solid lightgray;
  margin-bottom: 8px;
}
.content-step .passport-image .passport-face .image-tab-title {
  background: lightgray;
  font-weight: 600;
  text-align: center;
  font-size: 16px;
  white-space: nowrap;
}
.content-step .birth-certificate-face {
  border: 1px solid lightgray;
  margin-bottom: 8px;
  background: gray;
  text-align: center;
}
.content-step .birth-certificate-face img {
  margin: 16px 0;
}
.content-step .birth-certificate-face .image-tab-title {
  background: lightgray;
  font-weight: 600;
  text-align: center;
  font-size: 16px;
}
.content-step li {
  line-height: 1.4;
  margin-bottom: 8px;
}
.content-step {
  margin-bottom: 30px;
}
.content h4 {
  font-size: 18px;
  font-weight: bold;
}
.continue-button {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}
.continue-button button {
  display: inline-block;
  height: 55px;
  line-height: 55px;
  border: none;
  background: linear-gradient(to bottom, #63b8d8 0%, #357cb6 100%);
  border-radius: 4px;
  overflow: hidden;
  text-align: center;
  color: #fff;
  font-family: 'Myriad Pro Bold', sans-serif;
  padding: 0 20px;
  transition: 0.5s;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);
  text-decoration: none;
  font-size: 18px;
}
.continue-button button:hover {
  background: linear-gradient(to bottom, #74e8fc 0%, #375fb7 100%);
}
.content-container {
  width: 55.5555%;
  margin: 0 auto;
}
.content h3 {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 24px;
}
.instruction-title {
  text-align: center;
  color: #1f1f1f;
  text-transform: initial;
  font-weight: bold;
  font-size: 26px;
}
@media (max-width: 1200px) {
  .content-container {
    width: 80%;
  }
  .instruction-title {
    font-size: 22px;
  }
  .content h3 {
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .content-container {
    width: 100%;
    padding: 0 15px;
  }
  .content-step .passport-image {
    flex-wrap: wrap;
  }
}
</style>